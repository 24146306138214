import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import { Tooltip } from "antd";
import FormControl from "../../components/common/FormControl";
import Shared from "./Shared";
import ImageUpload from "../../components/common/ImageUpload";
import { toast } from "react-toastify";

const AddEdit = () => {
  const history = useNavigate();
  const { id } = useParams();
  const [images, setImages] = useState({ uploadDocument: "" });
  const [submitted, setSubmitted] = useState(false);
  const [form, setform] = useState({
    name: "",
    type: "",
  });
  const options = Shared.types;
  const formValidation = [
    { key: "name", required: true, message: "Category name is required" },
  ];
  useEffect(() => {
    if (id) {
      getOneCategory();      
    }
  }, [id]);

  const getOneCategory = () => {
    loader(true);
    ApiClient.get(`${Shared.detailApi}?id=${id}`).then((res) => {
      if (res.success == true || res?.code == 200) {
        loader(false);
        setform({ ...form, name: res?.data?.name, type: res?.data?.type });
        setImages({ uploadDocument: res?.data?.image });
      } else {
        loader(false);
      }
    });
  };

  const imageResult = (e, key) => {
    if (e.event == "remove") {
      setImages({ uploadDocument: "" });
    } else {
      setImages({ uploadDocument: e.value });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (invalid) return;
   else if ( !form?.type ) return;
  
    let method = "post";
    let url = Shared.addApi;
    let value;
    value = {
      ...form,
      image: images?.uploadDocument,
    };
    if (id) {
      method = "put";
      url = Shared.editApi;
      value = {
        ...form,
        image: images?.uploadDocument,
        id: id,
      };
    } else {
      delete value.id;
    }

    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success || res?.code == 200) {
        toast?.success(res?.message);
        history(`/${Shared.url}`);
        loader(false);
      } else {
        loader(false);
      }
    });
  }; 

  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="pprofile1">
            <div className="flex items-center mb-8">
              <Tooltip placement="top" title="Back">
                <Link
                  to={`/${Shared.url}`}
                  className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border transition-all  mr-3"
                >
                  <i className="fa fa-angle-left text-lg"></i>
                </Link>
              </Tooltip>
              <div>
                <h3 className="text-lg lg:text-2xl font-semibold text-[#111827]">
                  {id ? "Edit" : "Add"} {Shared.addTitle}
                </h3>
              </div>
            </div>

            <h3 className="ViewUser mb-3"></h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className=" mb-3">
                <FormControl
                  type="text"
                  name="name"
                  label="Name"
                  value={form.name}
                  onChange={(e) => setform({ ...form, name: e })}
                />
                {submitted && !form.name && (
                  <div className="invalid-feedback d-block">
                    Name is required
                  </div>
                )}
              </div>
              <div className=" mb-3">
                <FormControl
                  type="select"
                  name="type"
                  label=" category type"
                  value={form.type}
                  onChange={(e) => {
                    setform({ ...form, type: e });
                  }}
                  options={options}
                  theme="search"
                  required
                />
                {submitted && !form.type && (
                  <div className="invalid-feedback d-block">
                    Category type is required
                  </div>
                )}
              </div>

              <div className="mb-3">
                <label className="lablefontcls">Image</label>
                <br></br>
                <ImageUpload
                  model="category"
                  result={(e) => imageResult(e, "uploadDocument")}
                  value={images.uploadDocument}
                  label="Upload File"
                  type={images?.uploadDocument}
                  url="upload/image?modelName="
                  multiple={false}
                /> 
              </div>
            </div>

            <div className="text-right">
              <button
                type="submit"
                className="text-[#222222d6] bg-[#ffd6b6] bg-[#ffd6b6] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default AddEdit;
